import React, { Component } from "react"
import Layout from "../../components/layout/Layout"
import PageHelmet from "../../components/Helmet"
import BGImage from "gatsby-background-image"
import Img from "gatsby-image"

class ServicesMobile extends Component {
    render() {
        return(
            <>
                <PageHelmet pageTitle='Services > Mobile Development' />
                <Layout>
                    {/* Start Banner Area */}
                    <BGImage
                        fluid={this.props.data.headerBackgroundImage.childImageSharp.fluid}>
                        <div className="rn-page-title-area pt--120 pb--190"  data-black-overlay="8">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="rn-page-title text-center pt--100">
                                            <h2 className="title theme-gradient">MOBILE APPLICATIONS</h2>
                                            <p>Make Sure Your App is More than Just a Pretty Face</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </BGImage>
                    {/* End Banner Area */}

                    {/* Start Page Wrapper */}
                    <div className="rn-service-details ptb--120 bg_color--1">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="service-details-inner">
                                        <div className="inner">
                                            {/* Start Single Area */}
                                            <div className="row sercice-details-content pb--80 align-items-center">
                                                <div className="col-lg-6 col-12">
                                                    <div className="thumb">
                                                        <Img className="w-100" fluid={this.props.data.bodyImage1.childImageSharp.fluid} alt="Mobile App Image"/>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-12">
                                                    <div className="details mt_md--30 mt_sm--30">
                                                        <p>From connecting to customers to managing your business on the go, smartphones have become an indispensible part of our business toolkit.</p>
                                                        <p>When setting out to develop a mobile application it's critical to understand that while quality of the app itself is important, its the services and infrastructure that support that applicaiton that determine the overall sclability, availability, and security of your solution.</p>
                                                        <p>We ensure that your back-end infrastructure is as capable as your front-end applications, leveraging decades of experience delivering enterprise-scale applications that can service virtually unlimited users simultaneously and are fault-tolerant enough to achieve multuple 9's of uptime.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End Single Area */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Page Wrapper */}                      
                </Layout>
            </>
        )
    }
}

export default ServicesMobile

export const query = graphql`
    query {
        headerBackgroundImage: file(relativePath: { eq: "services-mobile-banner.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        bodyImage1: file(relativePath: { eq: "services-mobile.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }        
    }
`